:root {
  --primary-color: #6f56a3;
}

.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}
.ant-card .ant-card-head {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Solve_Easy_Custom_CSS_0f_AntD_Component */

.ant-modal-content .ant-modal-close {
  margin-top: 1.25rem;
  margin-right: 2rem;
}

.ant-modal-footer .ant-btn-primary {
  background-color: var(--primary-color);
}
.ant-btn-primary {
  background-color: var(--primary-color);
}

/*  */
.login-page {
  background-color: var(--primary-color);
}

.ant-menu .ant-menu-item {
  color: #fff;
}
.ant-menu .ant-menu-item-selected {
  color: black;
}

.opened-case-row .ant-btn-primary {
  background-color: var(--primary-color);
}

.ant-switch {
background: #BFBFBF;
}

.call-inprogress-card .ant-modal-content{
  top: 6rem;
}